@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.cdnfonts.com/css/tajawal');
* {
    font-family: 'Tajawal', sans-serif;
}

.custom-dropdown {
    width: 25rem !important;
}

.capitalize-first-letter {
    text-transform: lowercase; /* Ensures the whole text is lowercase */
  }
  
  .capitalize-first-letter::first-letter {
    text-transform: uppercase; /* Capitalizes only the first letter */
  }
  